import { Paper, styled } from '@mui/material'

export enum DialogSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge'
}

export type DialogSize = 'small' | 'medium' | 'large' | 'xlarge'

const sizesHeight = {
  [DialogSizes.Small]: '480px',
  [DialogSizes.Medium]: '640px',
  [DialogSizes.Large]: '640px',
  [DialogSizes.XLarge]: '720px'
}

export const paperDialogBySize = {
  [DialogSizes.Small]: {
    width: '432px',
    maxWidth: '432px'
  },
  [DialogSizes.Medium]: {
    width: '592px',
    maxWidth: '592px'
  },
  [DialogSizes.Large]: {
    width: '768px',
    maxWidth: '768px',
    minHeight: '576px'
  },
  [DialogSizes.XLarge]: {
    width: 'calc(100vw - 80px)',
    height: 'calc(100vh - 80px)',
    maxWidth: '960px'
  }
}

export const DialogPaper = styled(Paper, {
  shouldForwardProp: (prop: string) => !['size', 'fullScreen', 'height', 'width', 'hasForm'].includes(prop)
})<{
  size?: DialogSize
  fullScreen?: boolean
  height?: 'auto' | string
  width?: 'auto' | string
  hasForm?: boolean
}>(({ fullScreen, height, width, hasForm, size: size = DialogSizes.Small, theme }) => ({
  '&.MuiPaper-root': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(5),
    borderRadius: theme.spacing(1),
    ...paperDialogBySize[size],
    maxHeight: sizesHeight[size],

    [`@media (max-height:${sizesHeight[size]})`]: {
      maxHeight: 'calc(100vh - 80px)',
      minHeight: 'calc(100vh - 80px)'
    },

    ...(fullScreen && {
      width: '100vw',
      maxWidth: `calc(100vw - ${theme.spacing(10)})`,
      height: '100vh',
      maxHeight: `calc(100vh - ${theme.spacing(10)})`,
      margin: theme.spacing(5),
      borderRadius: theme.spacing(1)
    }),
    ...(height && {
      height: height,
      minHeight: height
    }),
    ...(width && {
      width: width
    }),

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      width: '100%',
      maxWidth: '100%',
      height: '100vh',
      maxHeight: '100vh',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 0,
      borderRadius: 0,

      ...(height && {
        top: 'auto',
        borderRadius: theme.spacing(1, 1, 0, 0),
        height: height,
        minHeight: height
      })
    },

    // Special case for Dialogs wrapped in Form
    ...(hasForm && {
      maxHeight: `calc(100vh - ${theme.spacing(10)})`,

      form: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        ...paperDialogBySize[size],
        maxWidth: '100%',

        ...(fullScreen && {
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          maxHeight: '100%'
        }),
        ...(height && {
          height: height,
          minHeight: height
        }),
        ...(width && {
          width: width
        }),

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          maxWidth: '100%',
          height: '100vh',
          maxHeight: '100vh',

          ...(height && {
            top: 'auto',
            borderRadius: theme.spacing(1, 1, 0, 0),
            height: height,
            minHeight: height
          })
        }
      }
    })
  }
}))
