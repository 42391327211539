import { useRef, useState, forwardRef, useEffect, ReactNode } from 'react'
import { Tooltip, TooltipProps, styled } from '@mui/material'

const TooltipRoot = styled(Tooltip)(() => ({
  whiteSpace: 'nowrap',
  maxWidth: 999,
  pointerEvents: 'auto'
}))

const Content = styled('span')(({ theme }) => ({
  display: 'block',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: 'inherit',
  paddingBottom: theme.spacing(0.25),

  '& > *': {
    display: 'inline'
  }
}))

export type EllipsisWithTooltipProps = Omit<TooltipProps, 'title' | 'children'> & {
  children: ReactNode
  title?: string
  forceTooltip?: boolean
}

const EllipsisWithTooltip = forwardRef(
  ({ children, title, forceTooltip, ...tooltipProps }: EllipsisWithTooltipProps, ref) => {
    const inputRef = useRef<HTMLDivElement>(null)
    const [overflowing, setOverflowing] = useState<boolean>(false)

    useEffect(() => {
      if (children && inputRef.current) {
        setOverflowing(forceTooltip || inputRef.current.scrollWidth > inputRef.current.clientWidth)
      }
    }, [inputRef, forceTooltip, children])

    useEffect(() => {
      if (typeof ref === 'function') {
        ref(inputRef.current)
      } else if (ref) {
        ref.current = inputRef.current
      }
    }, [ref])

    return (
      <TooltipRoot
        disableFocusListener={!overflowing}
        disableHoverListener={!overflowing}
        disableTouchListener={!overflowing}
        title={title || inputRef.current?.innerText || ''}
        describeChild
        {...tooltipProps}
      >
        <Content ref={inputRef}>{children}</Content>
      </TooltipRoot>
    )
  }
)

export default EllipsisWithTooltip
