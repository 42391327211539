export default {
  title: 'CARTO for developers',
  apiUrl: 'API Base URL',
  lds: 'LDS Token',
  disabledNoConnections: 'Please create a connection to your data warehouse first',
  accessToken: {
    title: 'API Access Tokens',
    content: {
      title: 'You have created <strong>{count, plural, one {# token} other {# tokens}}</strong>',
      link: 'Manage API Access Tokens'
    }
  },
  applications: {
    title: 'Built applications',
    button: 'Create new',
    buttonShort: 'New',
    table: {
      name: 'Name',
      clientId: 'Client ID',
      secret: 'Client Secret',
      shared: 'Shared'
    },
    wouldExceedQuotaModal: {
      create: {
        title: "Couldn't create new application"
      },
      moreInfo: {
        title: "Can't create new applications"
      },
      message: 'Check the exceeded quotas below before creating a new application.',
      alertMessage:
        'You can’t create any more applications with your current plan. Free up space by deleting other applications or request to be contacted for an upgrade.',
      quotaTitle: 'Applications'
    },
    quotaHit: 'You have used all the applications ({limit}) available in your current plan.'
  },
  guide: {
    overline: 'Guide',
    title: 'Learn how to create applications with CARTO from scratch'
  },
  docs: {
    title: 'Featured documentation',
    viewAll: 'View all'
  },
  feed: {
    title: 'Development updates',
    viewAll: 'View all updates'
  },
  table: {
    copy: 'Copy ID',
    copySecret: 'Copy Client Secret',
    copyId: 'Copy TitleId',
    copied: 'Copied to clipboard!'
  },
  updates: {
    error: {
      title: "The updates couldn't be shown right now, you can still read the {developersDocsLink}",
      developersDocsLink: 'developers news here'
    }
  }
}
